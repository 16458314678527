// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ListSubheader, Divider } from '@material-ui/core';
import { MenuItemLink, MenuProps } from 'react-admin';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem } from 'react-admin';

import {
  useSchemaContext,
  validateURLSchema,
  computePrefix,
} from 'context/SchemaContextProvider';
import { CustomSchemaDefinition } from 'context/UI';
import { CustomSchemaConfig } from 'context/CustomSchemaConfig';
import { CustomIcon } from './CustomIcon';

type Menu = {
  subtitle: string;
  childMenus: Array<{
    key: string;
    to: string;
    primaryText;
    config?: CustomSchemaConfig;
  }>;
};

const computeMenu = (
  schema: CustomSchemaDefinition,
  urlPathName: string
): Menu[] => {
  const args = validateURLSchema(urlPathName);

  const prefix = args ? computePrefix(args) : '';
  const subtitle =
    args?.type === `demo`
      ? `demo/${args.version}`
      : args?.type === `github` && args.owner && args.repo
      ? `Tables`
      : `Pick Repo`;

  return [
    {
      subtitle,
      childMenus: args
        ? Object.entries(schema)
            .filter(([key]) => key.startsWith(prefix) && key !== prefix)
            .map(([k, v]) => ({
              key: k,
              to: `/${k}`,
              primaryText:
                (v.schema ? v.schema.title : '') ||
                k.split('/').reverse()[0] ||
                k,
              config: v.config,
            }))
        : [],
    },
  ];
};

const MyMenu: React.FC<RouteComponentProps & MenuProps> = ({
  onMenuClick,
  logout,
  location,
}) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const { schema } = useSchemaContext();

  const menus = computeMenu(schema, location.pathname);

  return (
    <div>
      <DashboardMenuItem dense onClick={onMenuClick} sidebarIsOpen={open} />
      {menus.map(({ subtitle, childMenus }) => (
        <React.Fragment key={subtitle}>
          <Divider />
          {open && <ListSubheader>{subtitle}</ListSubheader>}
          {childMenus.map(({ key, to, primaryText, config }) => (
            <MenuItemLink
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              key={key}
              to={to}
              primaryText={primaryText}
              leftIcon={<CustomIcon config={config} alt={primaryText} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        </React.Fragment>
      ))}

      {isXSmall && logout}
    </div>
  );
};

export const CustomMenu = withRouter(MyMenu);
