import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';
import { NotificationProvider } from './NotificationProvider';
import { AuthContextProvider } from './AuthContextProvider';
import { loginWithGithub } from 'auth/auth';
import { SchemaContextProvider } from './SchemaContextProvider';
import { CustomAdminContext } from './CustomAdminContext';
import { DataProviderContextProvider } from './DataProviderContext';

const theme = {
  palette: {
    secondary: lightBlue,
  },
};

export const AppWrapper: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={createMuiTheme(theme)}>
      <CssBaseline />
      <NotificationProvider>
        <AuthContextProvider getValue={loginWithGithub}>
          <DataProviderContextProvider>
            <SchemaContextProvider>
              <CustomAdminContext theme={theme}>{children}</CustomAdminContext>
            </SchemaContextProvider>
          </DataProviderContextProvider>
        </AuthContextProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};
