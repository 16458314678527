import { getGithubApi } from 'github/getGithubApi';
import { getOrCreateNewBranch } from './getOrCreateNewBranch';

export const deleteFile = async (args: {
  owner: string;
  repo: string;
  branch: string;
  filePath: string;
  token: string;
}) => {
  const targetBranch = await getOrCreateNewBranch(args);

  const { owner, repo, token, filePath: path } = args;

  const ghApi = getGithubApi({ token });

  const { data } = await ghApi.repos.getContent({
    owner,
    repo,
    ref: targetBranch,
    path,
  });

  if (!('sha' in data)) {
    throw new Error(`E4567: No sha available for ${path} in ${owner}/${repo}`);
  }

  // TODO: Add sanity check is it used by other references
  await ghApi.repos.deleteFile({
    owner,
    repo,
    path,
    message: `feat(gitlify.com): remove file`,
    sha: data.sha,
  });

  return {};
};
