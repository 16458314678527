import { DataProvider } from 'react-admin';
import {
  CustomProxyArgs,
  CustomResourceConfiguration,
  TypedDataProvider,
} from './types';

export function wrapMethod<T extends keyof TypedDataProvider<any, any>>(
  customProxyArgs: CustomProxyArgs,
  inputMethods: { [key: string]: CustomResourceConfiguration<any, any> },
  methodName: T,
  boringFallback: DataProvider[T]
): DataProvider[T] {
  return (resource: string, innerArg: any) => {
    const relevantMethods = Object.entries(inputMethods)
      .map(([key, method]) => ({
        args: method.getPropsFromResource(resource),
        method,
        key,
      }))
      .filter((x) => x.args);

    if (relevantMethods.length === 0) {
      console.error(
        `E3647: No regsitered method applies for resource ${resource}. Registered methods: ${Object.keys(
          inputMethods
        ).join(', ')}.`
      );
      return (boringFallback as any)(resource, innerArg);
    } else if (relevantMethods.length === 0) {
      console.error(
        `E3648: More than one regsitered method applies for resource ${resource}. Applicable methods: ${relevantMethods
          .map((_) => _.key)
          .join(', ')}.`
      );
      return (boringFallback as any)(resource, innerArg);
    }

    const { method, key, args } = relevantMethods[0];

    const partialDataProvider = method.dataProvider(customProxyArgs);
    const partialDataProviderMethod = partialDataProvider[methodName];

    if (!partialDataProviderMethod) {
      console.error(
        `E3649: Method ${methodName} is not implemented for applicable method ${key}.`
      );
      return (boringFallback as any)(resource, innerArg);
    } else {
      return partialDataProviderMethod(args, innerArg);
    }
  };
}
