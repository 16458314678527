// https://github.com/benwinding/react-admin-firebase/blob/master/src-demo/src/CustomLoginPage.js
import React from 'react';
import {
  Login,
  //, LoginForm
} from 'react-admin';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useAuth } from 'context/AuthContextProvider';
import { Auth } from 'auth';
// import ForgotPasswordButton from './CustomForgotPassword'

// Configure FirebaseUI.
const getUiConfig = (
  setAuth: (auth: Auth) => void
): firebaseui.auth.Config => ({
  signInFlow: 'popup',
  signInSuccessUrl: '#/',
  signInOptions: [firebase.auth.GithubAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any) => {
      setAuth({ token: authResult.credential.accessToken });

      return true;
    },
  },
  privacyPolicyUrl: 'Hello',
  tosUrl: 'adddsa',
});

const SignInScreen = () => {
  const [, setAuth] = useAuth();

  return (
    <StyledFirebaseAuth
      uiConfig={getUiConfig(setAuth)}
      firebaseAuth={firebase.auth()}
    />
  );
};

const CustomLoginForm = (props) => (
  <div>
    {/* <div style={{fontFamily: "monospace", marginLeft: '15px'}}>
      <p>Username: test@example.com</p>
      <p>Password: password</p>
    </div>
    <LoginForm {...props} /> */}
    {/* <ForgotPasswordButton {...props} /> */}
    <SignInScreen />
  </div>
);

export const CustomLoginPage = (props) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);
