import React from 'react';
import Link from '@material-ui/core/Link';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import {
  // DiffIcon,
  GitBranchIcon,
  GitMergeIcon,
  GitPullRequestIcon,
  MarkGithubIcon,
} from '@primer/octicons-react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Add from '@material-ui/icons/Add';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import { FormDialog } from './SwitchBranchModal';
import { getPRURL } from 'dataprovider/github/getOrCreatePR';
import { useAuth } from 'context/AuthContextProvider';
import { LoadGithubFiles } from 'dataprovider/provider/LoadGithubFiles';

export const BreadCrumb = () => {
  const location = useLocation();

  const { type, repo, owner, branch, filePath } =
    LoadGithubFiles.getPropsFromResource(location.pathname) || {};
  return type === `github` && repo && owner && branch && filePath ? (
    <GithubBreadCrumb
      type={type}
      repo={repo}
      owner={owner}
      branch={branch}
      filePath={filePath}
    />
  ) : null;
};

export const GithubBreadCrumb: React.FC<{
  owner: string;
  repo: string;
  branch: string;
  filePath: string;
  type: string;
}> = ({ repo, owner, branch, filePath, type }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const setTargetBranch = (targetBranch: string) =>
    history.push(
      '/' +
        LoadGithubFiles.getBaseURL({
          type,
          repo,
          owner,
          branch: targetBranch,
          filePath,
        })
    );

  const [auth] = useAuth();

  return (
    <React.Fragment>
      <FormDialog setOpen={setOpen} open={open} setBranch={setTargetBranch} />
      <div
        style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}
      >
        <FormControl>
          <InputLabel style={{ display: 'flex' }} id="demo-simple-select-label">
            {' '}
            Project
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value="current"
          >
            <ListSubheader>Current version</ListSubheader>
            <MenuItem value="current" disabled>
              <MarkGithubIcon />
              &nbsp;{owner}/{repo}
            </MenuItem>
            <MenuItem value="pr">
              <MarkGithubIcon />
              &nbsp;
              <Link
                href={`https://github.com/${owner}/${repo}/`}
                target="_blank"
                rel="noreferrer"
                color="inherit"
              >
                Look at github{' '}
              </Link>
            </MenuItem>
            <Divider />
            <ListSubheader>Change project</ListSubheader>
            <MenuItem value="old">
              <SwapHoriz />{' '}
              <Link to={`/`} component={NavLink} color="inherit">
                Change project
              </Link>
            </MenuItem>
            <MenuItem value="new">
              <Add />{' '}
              <Link
                href={`https://wizard.gitlify.com/github/gitlify/demo/_/`}
                color="inherit"
              >
                Setup new project
              </Link>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel style={{ display: 'flex' }} id="demo-simple-select-label">
            Version
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value="current"
          >
            <ListSubheader>Current branch actions</ListSubheader>
            <MenuItem value="current" disabled={true}>
              <GitBranchIcon />
              &nbsp;{branch}
            </MenuItem>
            <MenuItem
              value="pr"
              onClick={async () => {
                const prUrl =
                  owner &&
                  repo &&
                  branch &&
                  (await getPRURL({
                    owner,
                    repo,
                    branch,
                    token: auth.token,
                  }));

                if (prUrl) {
                  window.open(prUrl, '_blank');
                } else {
                  window.alert(`No edit was yet made`);
                }
              }}
            >
              <GitPullRequestIcon />
              &nbsp;Go to github <ExitToApp />
            </MenuItem>
            <MenuItem value="merge">
              <GitMergeIcon />
              &nbsp;Publish / Merge
            </MenuItem>
            {/* <MenuItem value="compare"><DiffIcon />&nbsp;Look at changes</MenuItem> */}
            <Divider />
            <ListSubheader>Change the branch</ListSubheader>
            <MenuItem value="new" onClick={() => setOpen(true)}>
              <Add /> Create new session
            </MenuItem>
            <MenuItem value="old">
              <SwapHoriz />{' '}
              <Link
                to={`/${owner}/${repo}`}
                component={NavLink}
                color="inherit"
              >
                Pick an existing one
              </Link>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
};
