import * as React from 'react';
import {
  DateInput,
  DateTimeInput,
  TextInput,
  FileInput,
  ImageInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const InputComponents = {
  'date-time': (props) => <DateTimeInput {...props} />,
  date: (props) => <DateInput {...props} />,
  time: (props) => <TextInput {...props} type="time" />,
  email: (props) => <TextInput {...props} type="email" />,
  'idn-email': (props) => <TextInput {...props} type="email" />,
  uri: (props) => <TextInput {...props} type="url" />,
  url: (props) => <TextInput {...props} type="url" />,
  'url-img': (props) => <TextInput {...props} type="url" />,
  richtext: (props) => <RichTextInput {...props} />,
  'data-uri': (props) => <FileInput {...props} />,
  'data-uri-img': (props) => <ImageInput {...props} />,
};
