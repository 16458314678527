import { getGithubApi } from './getGithubApi';
import { loginWithGithub } from 'auth/auth';
import { loader } from 'graphql.macro';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const query = loader('./query/load-tree.gql');

type Api = any;

type File = {
  name: string;
  path: string;
  object: {
    text?: string;
    entries?: File[];
  };
};

export type FlatFile = {
  path: string;
  content: string;
};

export const recursionTree = (entries: File[] = []): Array<FlatFile> =>
  entries.reduce(
    (acc: FlatFile[], x: File) =>
      x && x.path && x.object
        ? [
            ...acc,
            ...(x.object.text
              ? [
                  {
                    path: x.path,
                    content: x.object.text,
                  },
                ]
              : []),
            ...(x.object.entries ? recursionTree(x.object.entries) : []),
          ]
        : acc,
    []
  );

export const getFiles = async ({
  repo,
  owner,
  path,
  apolloClient,
}: {
  repo: string;
  owner: string;
  path?: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}): Promise<{ path: string; content: string }[]> => {
  const result = await apolloClient.query({
    query,
    variables: {
      repo: repo,
      owner: owner,
      query: `HEAD:${path || ''}`,
    },
  });

  return result.data.repository && result.data.repository.folder
    ? recursionTree(result.data.repository.folder.entries).filter(Boolean)
    : [];
};

export const githubApiUtilityMethods: Api = {
  loginAndGetToken: loginWithGithub,
  getRepo: ({ token, owner, repo }) => {
    return getGithubApi({ token })
      .repos.get({ owner, repo })
      .then(
        ({ data: { default_branch } }) => ({ default_branch }),
        (e) => undefined
      );
  },
  createRepo: async ({ token, owner, repo, isPublic }) => {
    const gh = getGithubApi({ token });

    const description = `Created by gitlify.com via ${document.location.href}`;

    const currentlyLoggedIn = await gh.users.getAuthenticated();

    const isOrg = currentlyLoggedIn.data.login !== owner;
    const createPromise = isOrg
      ? gh.repos.createInOrg({
          name: repo,
          org: owner,
          description,
          private: !isPublic,
          auto_init: true,
        })
      : gh.repos.createForAuthenticatedUser({
          name: repo,
          description,
          private: !isPublic,
          auto_init: true,
        });

    return createPromise.then(({ data: { default_branch } }) => ({
      default_branch,
    }));
  },
  createPR: async ({
    token,
    owner,
    repo,
    defaultBranch,
    targetBranch,
    title,
    body,
  }) => {
    const gh = getGithubApi({ token });

    const res = await gh.pulls.create({
      owner,
      repo,
      maintainer_can_modify: true,
      // draft: true, not supported everywhere https://docs.github.com/en/free-pro-team@latest/github/collaborating-with-issues-and-pull-requests/about-pull-requests#draft-pull-requests
      title,
      body,
      base: defaultBranch,
      head: targetBranch,
    });

    return { address: res.data.url };
  },
  createBranch: async ({ token, owner, repo, branch, defaultBranch }) => {
    const gh = getGithubApi({ token });

    const sha = await gh.git
      .getRef({ owner, repo, ref: `heads/${defaultBranch}` })
      .then((x) => x.data.object.sha);

    await gh.git.createRef({ owner, repo, ref: `refs/heads/${branch}`, sha });

    return { branch };
  },
  starRepo: async ({ token, owner, repo }) => {
    await getGithubApi({ token }).activity.starRepoForAuthenticatedUser({
      owner,
      repo,
    });
  },
  writeFiles: async ({ token, owner, repo, bucketIndex }, file) => {
    return getGithubApi({
      token,
      parallelId:
        bucketIndex === undefined ? undefined : `write-${bucketIndex}`,
    })
      .git.createBlob({
        owner,
        repo,
        content: file.content,
        encoding: 'utf-8',
      })
      .then((x) => ({
        sha: x.data.sha,
        path: file.path,
      }));
  },
  commitFiles: async ({ token, owner, repo, files, targetBranch }) => {
    const gh = getGithubApi({ token });

    const shas = await gh.git
      .listMatchingRefs({ owner, repo, ref: `heads/${targetBranch}` })
      .then((x) => x.data);

    if (shas.length === 0) {
      throw new Error(`E4578: Ref for heads/${targetBranch} not found`);
    }

    const sha = shas[0].object.sha;

    const tree = await gh.git.createTree({
      owner,
      repo,
      tree: files.map((file) => ({
        path: file.path,
        mode: '100644',
        type: 'blob',
        sha: file.sha,
      })),
      base_tree: sha,
    });

    const commit = await gh.git.createCommit({
      owner,
      repo,
      tree: tree.data.sha,
      message: `feat(gitlify-setup): files added by gitlify.com via ${document.location.href}`,
      parents: [sha],
    });

    await gh.git.updateRef({
      owner,
      repo,
      ref: `heads/${targetBranch}`,
      sha: commit.data.sha,
    });

    return { commitSha: commit.data.sha };
  },
};
