import { getGithubApi } from 'github/getGithubApi';

export const getDefaultBranch = ({
  token,
  owner,
  repo,
}: {
  token: string;
  owner: string;
  repo: string;
}) =>
  getGithubApi({ token })
    .repos.get({ owner, repo })
    .then((_) => _.data.default_branch);
