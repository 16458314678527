import { getGithubApi } from 'github/getGithubApi';
import { githubApiUtilityMethods } from 'github/api';
import { getOrCreateNewBranch } from './getOrCreateNewBranch';
import { getDefaultBranch } from './getDefaultBranch';

export const getPRs = async (args: {
  owner: string;
  repo: string;
  token: string;
  branch: string;
  per_page?: number;
  page?: number;
}) => {
  const { owner, repo, branch, token, per_page, page } = args;

  const ghApi = getGithubApi({ token });

  const prs = await ghApi.pulls.list({
    owner,
    repo,
    head: branch,
    sort: 'created',
    per_page,
    page,
  });

  return prs.data;
};

export const getPRURL = async (args: {
  owner: string;
  repo: string;
  token: string;
  branch: string;
}) => {
  const prs = await getPRs(args);

  return prs[0]?.html_url;
};

export const getOrCreatePRAgainstDefaultBranch = async (args: {
  owner: string;
  repo: string;
  token: string;
  branch: string;
}) => {
  const { owner, repo, token } = args;

  const tgBranch = await getOrCreateNewBranch(args);
  const defaultBranch = await getDefaultBranch({
    owner,
    repo,
    token,
  });

  const ghApi = getGithubApi({ token });

  const prs = await ghApi.pulls.list({
    owner,
    repo,
    base: defaultBranch,
    head: tgBranch,
    sort: 'created',
  });

  console.log({ prs });

  if (prs.data.length > 0) {
    return { address: prs.data[0].html_url };
  } else {
    return githubApiUtilityMethods.createPR({
      token,
      owner,
      repo,
      defaultBranch,
      targetBranch: tgBranch,
      title: `[gitlify.com]: PR for ${tgBranch}`,
      body: `Autogenerated by the native git CMS.`,
    });
  }
};
