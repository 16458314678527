import * as React from 'react';
import {
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  TextInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  SimpleForm,
  required as validateRequired,
  CheckboxGroupInput,
  Edit,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import { InputComponents } from './InputComponents';
import { mapToRead } from './ViewComponents';

import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { CustomSchemaDefinition } from 'context/UI';
import { BreadCrumb } from './BreadCrumb';
import { getReferenceDataFromRef } from 'dataprovider/provider/LoadGithubSchema';
import { CustomShowGuesser } from './CustomShowGuesser';
import { getRepoDataFromResource } from 'dataprovider/provider/getRepoDataFromResource';

type CustomError = {
  code: number;
  error: string;
  name: string;
  source?: string;
  schema?: JSONSchema7;
};

const getReferenceSchema = (
  schema: JSONSchema7,
  allSchemas: CustomSchemaDefinition,
  name: string,
  source: string
): {
  error?: CustomError;
  schema?: JSONSchema7Definition;
  name?: string;
  propertyPath?: string;
} => {
  const ref = schema.$ref;

  if (!ref) {
    return {};
  }

  if (ref.startsWith('#')) {
    const key = ref.replace('#/definitions/', '');
    const nextSchema = schema.definitions ? schema.definitions[key] : undefined;

    if (nextSchema) {
      return {
        schema: nextSchema,
      };
    }

    return {
      error: {
        code: 7845,
        error: `Not definition at key ${key} for source ${source}. Note that internal refs need to point to the #/definitions/[key].`,
        name,
        source,
      },
    };
  }

  const { reference, property } = getReferenceDataFromRef(ref);

  if (!allSchemas[reference]) {
    return {
      error: {
        code: 4688,
        error: ` Unsupported ${ref} for source ${source}. CustomSchema ${reference} is not one of ${Object.keys(
          allSchemas
        )}.`,
        source,
        name,
      },
    };
  }

  console.log({ property });

  return {
    schema: allSchemas[reference].schema,
    name: reference,
    propertyPath: property,
  };
};
const getOptions = (schema: any) =>
  schema.enum
    ? schema.enum.map((x, i) => ({
        id: x,
        name: schema.enumNames
          ? schema.enumNames[i]
          : typeof x === `object`
          ? JSON.stringify(x)
          : x,
      }))
    : undefined;
const createErrorComp = (x: CustomError): React.ReactNode => (
  <div>
    {x.code}
    <br />
    {x.error}
    <br />
    source {x.source} at name {x.name}
    <br />
    {JSON.stringify(x.schema)}
  </div>
);
export const mapToInput = (args: {
  resource: string;
  source?: string;
  schema: JSONSchema7Definition;
  originalSchema: JSONSchema7;
  name: string;
  allSchemas: CustomSchemaDefinition;
  isRequired: boolean;
  read: boolean;
  shallow: boolean;
  asArray: boolean;
}): Array<React.ReactNode> | React.ReactNode => {
  const {
    resource,
    source = '',
    schema,
    originalSchema,
    name,
    allSchemas,
    isRequired,
    read,
    shallow,
    asArray,
  } = args;

  if (typeof schema === `boolean`) {
    return createErrorComp({
      code: 7545,
      error: `schema of type boolean at source ${
        source || ''
      } at resource ${resource} is not allowed`,
      name,
      source,
    });
  }

  const {
    type,
    const: constant,
    properties,
    enum: enumerator,
    required,
    readOnly,
    writeOnly,
    // uniqueItems,
    multipleOf,
    // minLength,
    // maxLength,
    // minItems,
    // contains,
    allOf,
    items,
    oneOf,
    anyOf,
    additionalItems,
  } = schema;

  const isOnlyReadable = readOnly || read;

  if (isOnlyReadable) {
    return mapToRead({ resource, source, schema, shallow });
  }

  const {
    schema: referenceSchema,
    error,
    name: remoteRefName,
    propertyPath,
  } = getReferenceSchema(schema, allSchemas, name, source || '');

  if (error) {
    return createErrorComp(error);
  }

  if (shallow && source) {
    return null;
  }

  if (writeOnly && read) {
    // read only
    return null;
  }

  if (Array.isArray(type) || oneOf || anyOf || allOf || constant) {
    return createErrorComp({
      code: 4587,
      error: `Type being an array, const, anyOf or oneOf is not yet supported. Input was type=${JSON.stringify(
        type
      )}`,
      schema,
      source,
      name,
    });
  }

  if (remoteRefName) {
    if (asArray) {
      return (
        <ReferenceArrayInput source={source} reference={remoteRefName}>
          <SelectArrayInput optionText={propertyPath} />
        </ReferenceArrayInput>
      );
    } else {
      return (
        <ReferenceInput
          key={source}
          source={source}
          reference={remoteRefName}
          label={schema.title}
          helperText={schema.description}
        >
          <SelectInput optionText={propertyPath} />
        </ReferenceInput>
      );
    }
  } else if (referenceSchema) {
    return mapToInput({
      resource,
      source,
      schema: referenceSchema,
      originalSchema,
      name,
      allSchemas,
      isRequired: false,
      read,
      shallow,
      asArray: true,
    });
  } else if (enumerator) {
    if (asArray) {
      const options = getOptions(schema);
      return options.length > 10 ? (
        <CheckboxGroupInput
          source={source}
          key={source}
          choices={options}
          validate={isRequired ? [validateRequired] : []}
        />
      ) : (
        <AutocompleteArrayInput
          key={source}
          source="category"
          choices={options}
        />
      );
    } else {
      const options = getOptions(schema);
      if (options.length > 10) {
        return <AutocompleteInput source={source} options={options} />;
      } else {
        return <SelectInput source={source} options={options} />;
      }
    }
  } else if (items) {
    const fixedItems = (Array.isArray(items) ? items : []).map((x, i) =>
      mapToInput({
        resource,
        source: `${source}.${i}`,
        schema: x,
        originalSchema,
        name,
        allSchemas,
        isRequired: false,
        read,
        shallow,
        asArray: false,
      })
    );

    const arrayItems = Array.isArray(items) ? additionalItems : items;

    if (!arrayItems) {
      return fixedItems;
    }

    if (arrayItems && items) {
      return createErrorComp({
        code: 7845,
        error: `Due to technical limitations we can't support additionalItems at the moment. `,
        schema,
        source,
        name,
      });
    }

    return mapToInput({
      resource,
      source,
      schema: arrayItems,
      originalSchema,
      name,
      allSchemas,
      isRequired: false,
      read,
      shallow,
      asArray: true,
    });
  } else if (asArray) {
    return (
      <ArrayInput source={source}>
        <SimpleFormIterator>
          {mapToInput({
            resource,
            source,
            schema,
            originalSchema,
            name,
            allSchemas,
            isRequired: false,
            read,
            shallow,
            asArray: false,
          })}
        </SimpleFormIterator>
      </ArrayInput>
    );
  } else if (properties) {
    const elements = Object.entries(properties).map(
      ([childSource, childSchema]) =>
        mapToInput({
          resource,
          source: source ? `${source}.${childSource}` : childSource,
          schema: childSchema,
          originalSchema,
          name,
          allSchemas,
          isRequired: required ? required.includes(childSource) : false,
          read,
          shallow,
          asArray: false,
        })
    );

    return !source ? (
      elements
    ) : (
      <Accordion>
        <AccordionSummary>
          <Typography>{schema.title || source}</Typography>
        </AccordionSummary>
        <AccordionDetails>{elements}</AccordionDetails>
      </Accordion>
    );
  } else if (type === 'string') {
    if (schema.format) {
      const InputComponent = InputComponents[schema.format];
      if (InputComponent) {
        return (
          <InputComponent
            key={source}
            source={source}
            resetable
            helperText={schema.description}
            label={schema.title}
          />
        );
      }
    }

    return (
      <TextInput
        key={source}
        source={source}
        label={schema.title}
        helperText={schema.description}
      />
    );
  } else if (type === 'integer' || type === 'number') {
    return (
      <NumberInput
        key={source}
        source={source}
        step={multipleOf || schema.type === 'integer' ? 1 : undefined}
        label={schema.title}
        helperText={schema.description}
        max={schema.maximum}
        min={schema.minimum}
      />
    );
  } else if (type === 'boolean') {
    return (
      <BooleanInput
        key={source}
        label={schema.title}
        helperText={schema.description}
        source={source}
      />
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
);

export const CustomEditGuesser: React.FC<{
  WrapperComp?: (p: any) => React.ReactElement;
  uiSchema?: object;
  schema: JSONSchema7;
  schemaName: string;
  allSchemas: CustomSchemaDefinition;
  [key: string]: any;
}> = ({
  WrapperComp = Edit,
  schemaName,
  uiSchema,
  schema,
  allSchemas,
  ...props
}) => {
  const location = useLocation();
  const { readonly } = getRepoDataFromResource(location.pathname) || {};
  if (readonly) {
    return (
      <CustomShowGuesser
        schemaName={schemaName}
        uiSchema={uiSchema}
        schema={schema}
        {...props}
      />
    );
  }

  const mapped = mapToInput({
    resource: schemaName,
    source: '',
    schema,
    originalSchema: schema,
    name: schemaName,
    allSchemas,
    isRequired: false,
    read: false,
    shallow: false,
    asArray: false,
  });

  return (
    <React.Fragment>
      <BreadCrumb />
      <WrapperComp {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>{mapped}</SimpleForm>
      </WrapperComp>
    </React.Fragment>
  );
};
