import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import generate from 'project-name-generator';
import Redo from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';
import { LoadGithubFiles } from 'dataprovider/provider/LoadGithubFiles';

export const SwitchBranchModal: React.FC<{
  setOpen: (x: boolean) => void;
  open: boolean;
}> = ({ setOpen, open }) => {
  const location = useLocation();
  const history = useHistory();
  const { repo, owner, filePath, type } =
    LoadGithubFiles.getPropsFromResource(location.pathname) || {};

  return repo && owner && filePath && type ? (
    <FormDialog
      setOpen={setOpen}
      open={open}
      setBranch={(targetBranch) =>
        history.push(
          `/` +
            LoadGithubFiles.getBaseURL({
              repo,
              owner,
              filePath,
              branch: targetBranch,
              type,
            })
        )
      }
    />
  ) : null;
};

const branchPrefix = `gitlify/`;

export const FormDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  setBranch: (br: string) => void;
}> = ({ open, setOpen, setBranch }) => {
  const [text, setText] = React.useState(generate().dashed);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const selectBranch = () => {
    setBranch(`${branchPrefix}${text}`);

    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Choose or generate a name
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Branch name"
            type="string"
            value={text}
            fullWidth
            onChange={handleChange}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">gitlify/</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setText(generate().dashed)}>
                    <Redo />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abort</Button>
          <Button onClick={selectBranch} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
