import React from 'react';
import { CustomContext } from './createCustomContext';

export const createStrictContext = function <T>(
  ns: String,
  EmptyComponent: React.FC<{
    setValue: (x?: T) => void;
    getValue: GetValue<T>;
    value?: T;
  }>
) {
  const Ctx = React.createContext<CustomContext<T> | null>(null);

  const ContextProvider: React.FC<{ getValue: GetValue<T> }> = ({
    children,
    getValue,
  }) => {
    const [value, setValue] = React.useState<T>();

    return (
      <React.Fragment>
        <EmptyComponent getValue={getValue} setValue={setValue} value={value} />
        {value && (
          <Ctx.Provider value={{ value, setValue }}>{children}</Ctx.Provider>
        )}
      </React.Fragment>
    );
  };

  return {
    ContextProvider,
    use: (): [T, (t: T) => void] => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ctx = React.useContext(Ctx);

      if (ctx === null) {
        throw new Error(
          `E4675: Implementation error - Provider for ${ns} was forgotten`
        );
      }

      if (ctx.value === undefined) {
        throw new Error(
          `E4685: Implementation error - Strict Provider fallback for ${ns} doesn't correctly set fallback value`
        );
      }

      return [ctx.value, ctx.setValue];
    },
  };
};

type GetValue<T> = () => Promise<T>;
