export const getRepoDataFromResource = (
  resource: string
): ResourceProps | undefined => {
  const [type, owner, repo, ...branch] = resource
    .replace(/^\//, '')
    .split('/.')[0]
    .split('/');

  if (owner && repo && branch.length > 0) {
    return {
      type,
      owner,
      repo,
      branch: branch.join('/'),
      readonly: branch[0] !== 'gitlify',
    };
  } else {
    return undefined;
  }
};

export type ResourceProps = {
  type: string;
  owner: string;
  repo: string;
  branch: string;
  readonly: boolean;
};
