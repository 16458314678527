import { FirebaseAuthProvider } from 'react-admin-firebase';
import firebase from '@firebase/app';
// These imports load individual services into the firebase namespace.
import '@firebase/auth';

import { firebaseConfig } from './firebase-config';

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseAuthProvider = FirebaseAuthProvider(firebaseConfig, {
  lazyLoading: {
    enabled: true,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
});
