import { updateFile } from './createOrUpdateFile';
import { getOrCreateNewBranch } from './getOrCreateNewBranch';

export const updateFileContent = async (args: {
  owner: string;
  branch: string;
  repo: string;
  filePath: string;
  data: any;
  token: string;
}) => {
  const targetBranch = await getOrCreateNewBranch(args);

  const { owner, repo, token, data, filePath } = args;

  return updateFile({
    owner,
    repo,
    token,
    path: filePath,
    updateContent: (json) => ({
      ...json,
      ...data,
    }),
    sortByKey: true,
    branch: targetBranch,
  });
};
