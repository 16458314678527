import { getGithubApi } from 'github/getGithubApi';
import { githubApiUtilityMethods } from 'github/api';
import { getDefaultBranch } from './getDefaultBranch';

export const getOrCreateNewBranch = async ({
  owner,
  repo,
  branch,
  token,
}: {
  owner: string;
  repo: string;
  branch: string;
  token: string;
}) => {
  const ghApi = getGithubApi({ token });
  try {
    const resp = await ghApi.repos.getBranch({
      owner,
      repo,
      branch,
    });

    return resp.data.name;
  } catch (e) {
    const defaultBranch = await getDefaultBranch({
      owner,
      repo,
      token,
    });

    return githubApiUtilityMethods
      .createBranch({
        token,
        owner,
        repo,
        defaultBranch,
        branch,
      })
      .then((x) => x.branch);
  }
};
