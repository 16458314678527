import React from 'react';
import { ReactComponent as LogoSVG } from 'logo.svg';

import './logo.css';
import clsx from 'clsx';

const Name: React.FC<{ className?: string }> = ({ className }) => (
  <div className={clsx('gf__logo__bn gf__logo__bn--header', className)}>
    gitlify
  </div>
);

export const Logo: React.FC<{ hideOnXS?: boolean }> = ({ hideOnXS }) => (
  <div className="gf__logo">
    <div>
      <LogoSVG />
    </div>
    <Name />
  </div>
);
