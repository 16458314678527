import { firebaseAuthProvider } from 'auth/firebase-auth';
import { history } from 'custom_history';
import { AdminContext } from 'react-admin';
import { useDataProvider } from './DataProviderContext';

export const CustomAdminContext: React.FC<{ theme: object }> = ({
  theme,
  children,
}) => {
  const { dataProvider } = useDataProvider();

  return (
    <AdminContext
      dataProvider={dataProvider}
      history={history}
      authProvider={firebaseAuthProvider}
    >
      {children}
    </AdminContext>
  );
};
