export const sortBy = <T>(
  a: T[],
  by: (t: T) => number,
  dir: 'ASC' | 'DESC' = 'ASC'
) => a.sort((x: T, y: T) => (dir === 'ASC' ? by(y) - by(x) : by(x) - by(y)));

export const sortByString = <T>(
  a: T[],
  by: (t: T) => string,
  dir: 'ASC' | 'DESC' = 'ASC'
) => {
  return [...a].sort((x: T, y: T) =>
    dir === 'ASC' ? by(y).localeCompare(by(x)) : by(x).localeCompare(by(y))
  );
};
