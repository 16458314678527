import { JSONSchemaType } from 'ajv';
import { CustomSchema } from 'context/UI';
import { CustomProxyArgs } from 'dataprovider/types';
import { typedGraphQLAPI } from 'github/query';
import { JSONSchema7 } from 'json-schema';
import { GetListParams, GetListResult, Record } from 'react-admin';
import { tryParseJson } from '_helper/tryParseJson';
import { ThenArgRecursive } from '_helper/ThenArgs';
import { Auth } from 'auth';
import { getResourceName } from 'urls/github';
import { getBranchWithDefaultFallback } from '../github/getBranchWithDefaultFallback';
import {
  ResourceProps,
  getRepoDataFromResource,
} from './getRepoDataFromResource';

export type RepoData = {
  id: string;
  name: string;
};

export const repoDataSchema: JSONSchemaType<RepoData> & JSONSchema7 = {
  type: `object`,
  required: ['id', 'name'],
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: `string`,
    },
  },
};

const getPropsFromResource = (resource: string): ResourceProps | undefined => {
  if (resource.split('/.').length === 1) {
    return getRepoDataFromResource(resource);
  } else {
    return undefined;
  }
};

const getEntriesFromRepo = async (
  { repo, owner, branch }: { repo: string; owner: string; branch: string },
  auth
) => {
  const res = await typedGraphQLAPI.loadSchema({
    owner,
    repo,
    query: `${branch || `HEAD`}:.gitlify`,
    token: auth.token,
  });

  const { data, error } = res;

  if (error) {
    throw error;
  }

  const folder = data?.repository?.folder;

  return folder && `entries` in folder ? folder.entries : undefined;
};

type GetResultsArg = {
  entries: ThenArgRecursive<ReturnType<typeof getEntriesFromRepo>>;
  repo: string;
  owner: string;
  branch: string;
};

const getEntriesFromGithub = async (auth): Promise<GetResultsArg[]> => {
  // TODO: Search query for it and iterate only over relavant repos (maybe allow filtering for org)
  const res = await typedGraphQLAPI.loadRepos({
    token: auth.token,
  });

  const { data, error } = res;

  if (error) {
    throw error;
  }

  const extract = ({
    owner,
    repositories,
  }: {
    owner?: string;
    repositories: any;
  }): GetResultsArg[] => {
    return owner
      ? repositories?.nodes
          ?.map((x): GetResultsArg | undefined => {
            const repo = x?.name;
            const branch = x?.defaultBranchRef?.name;
            const object = x?.object;
            const res =
              object && repo && branch
                ? {
                    entries: `entries` in object ? object.entries : [],
                    owner,
                    repo: x?.name,
                    branch: x?.defaultBranchRef?.name,
                  }
                : undefined;
            return res;
          })
          .filter(Boolean) || []
      : [];
  };

  const personalResults = extract({
    owner: data?.viewer?.login,
    repositories: data?.viewer?.repositories,
  });

  const orgResults =
    data?.viewer?.organizations?.nodes?.reduce(
      (acc: GetResultsArg[], node): GetResultsArg[] => {
        return [
          ...acc,
          ...extract({
            owner: node?.login,
            repositories: node?.repositories,
          }),
        ];
      },
      []
    ) || [];

  return [...personalResults, ...orgResults];
};

type ReturnArray = Array<{ schema: CustomSchema } & RepoData>;

const jsonPathFormProperty = (jsonPath: string) =>
  jsonPath
    .replace(/^\//, '') // remove starting slash
    .split('/')
    .filter((_, i) => i % 2 === 1)
    .join('.');

export const getReferenceDataFromRef = ($ref: string) => {
  const [reference, property] = $ref.split('#');

  if (!property || !reference) {
    throw new Error(`E4567: $ref ${$ref} is not a valid reference`);
  }

  return {
    reference,
    propertyId: 'id',
    property: jsonPathFormProperty(property),
  };
};

/**
 * Replacing $ref `users#/properties/id` by $ref `github/:owner/:branch/.gitlfy/users#/properties/id`
 */
const replaceRefs = (
  x: JSONSchema7 & JSONSchemaType<any>,
  args: { repo: string; owner: string; branch: string }
): JSONSchema7 & JSONSchemaType<any> => {
  const [start, ...rest] = JSON.stringify(x).split('"$ref":"');
  const transformRest = rest.map((x) => {
    const [name, ...r] = x.split('#');

    if (name) {
      return [
        getResourceName({
          name,
          ...args,
        }),
        ...r,
      ].join('#');
    } else {
      return x;
    }
  });

  return JSON.parse([start, ...transformRest].join('"$ref":"'));
};

const standartize = async (
  el: CustomSchema,
  args: { repo: string; owner: string; branch: string }
): Promise<CustomSchema> => {
  return {
    ui: el.ui,
    config: el.config,
    schema: replaceRefs(el.schema, args),
  };
};

const getResults = ({
  entries,
  repo,
  owner,
  branch,
}: GetResultsArg): Promise<ReturnArray> =>
  entries?.reduce(
    (promAcc, entry): Promise<ReturnArray> =>
      promAcc.then(async (acc: ReturnArray) => {
        const entryObject = entry?.object;
        const innerEntries =
          entryObject && 'entries' in entryObject
            ? entryObject.entries || []
            : [];
        const el = {};

        innerEntries.forEach((object) => {
          const innerObject = object?.object;
          el[object?.name?.replace('.json', '')] =
            innerObject && `text` in innerObject && innerObject.text
              ? tryParseJson(innerObject.text)
              : undefined;
        });

        if (!entry?.name) {
          console.warn(`E4754: Entry should always have a name`);
          return acc;
        }

        if (!el['schema']) {
          console.warn(`E4784: Schema resourece ${entry?.name} has no schema`);
          return acc;
        }

        return [
          ...acc,
          {
            id: getResourceName({
              name: entry?.name,
              owner,
              repo,
              branch,
            }),
            name: entry?.name,
            schema: await standartize(el as CustomSchema, {
              repo,
              owner,
              branch,
            }),
          },
        ];
      }),
    Promise.resolve<ReturnArray>([])
  ) || Promise.resolve([]);

const getSchema = async (
  { repo, owner, branch }: { repo?: string; owner?: string; branch?: string },
  auth: Auth
): Promise<ReturnArray> => {
  let data = !owner || !repo ? await getEntriesFromGithub(auth) : [];

  if (owner && repo) {
    const realBranch = await getBranchWithDefaultFallback({
      repo,
      owner,
      branch,
      token: auth.token,
    });
    const entries = await getEntriesFromRepo(
      { repo, owner, branch: realBranch },
      auth
    );

    data.push({
      entries,
      repo,
      owner,
      branch: branch || realBranch,
    });
  }

  return Promise.all(data.map(getResults)).then((p) =>
    p.reduce((_, __) => [..._, ...__], [] as ReturnArray)
  );
};

type Result = RepoData & Record & { schema: CustomSchema };

const prefix = `github_load_schema`;

export const LoadGithubSchema =
  // : CustomResourceConfiguration<
  //   ResourceProps,
  //   Result
  // >
  {
    prefix,
    getBaseURL: (props: ResourceProps) =>
      [prefix, props.owner, props.repo, props.branch].join('/'),
    getPropsFromResource,
    schema: {
      schema: repoDataSchema as any,
      config: {
        iconClass: `fas fa-database`,
        noEdit: true,
        noCreate: true,
        noShow: true,
      },
      ui: {},
    },
    dataProvider: (customProxyArgs: CustomProxyArgs) => ({
      getList: async (
        props: Partial<ResourceProps>,
        _: GetListParams | undefined
      ): Promise<GetListResult<Result>> => {
        const { owner, repo, branch } = props;

        return getSchema(
          {
            owner,
            repo,
            branch,
          },
          customProxyArgs.githubAuth
        ).then((schemas) => {
          return {
            total: schemas.length,
            data: schemas as any[],
          };
        });
      },
    }),
  };
