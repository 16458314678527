import * as React from 'react';
import {
  BooleanField,
  EmailField,
  ReferenceField,
  TextField,
  UrlField,
  DateField,
  NumberField,
  RichTextField,
  ImageField,
  FileField,
  SelectField,
  Filter,
} from 'react-admin';
import { JSONSchema7Definition } from 'json-schema';
import { getReferenceDataFromRef } from 'dataprovider/provider/LoadGithubSchema';

export const PostFilter: React.FC = () => {
  return (
    <Filter>
      <span />
    </Filter>
  );
};

const ViewComponents = {
  'date-time': (props) => <DateField {...props} showTime />,
  date: (props) => <DateField {...props} />,
  time: (props) => <TextField {...props} />,
  email: (props) => <EmailField {...props} />,
  'idn-email': (props) => <TextField {...props} />,
  uri: (props) => <UrlField {...props} />,
  url: (props) => <UrlField {...props} />,
  'url-img': (props) => <ImageField {...props} />,
  richtext: (props) => <RichTextField {...props} stripTags />,
  'data-uri': (props) => <FileField {...props} />,
  'data-uri-img': (props) => <ImageField {...props} />,
};

export const mapToRead = ({
  resource,
  source,
  schema,
  shallow,
}: {
  resource: string;
  source: string;
  schema: JSONSchema7Definition;
  shallow: boolean;
}): React.ReactElement | null | React.ReactElement[] => {
  if (typeof schema === `boolean`) {
    return null;
  }

  if (shallow && schema.properties && source) {
    return null;
  }

  if (schema.properties) {
    return Object.entries(schema.properties || {})
      .map(([x, y]) =>
        mapToRead({
          resource,
          source: x,
          schema: y,
          shallow,
        })
      )
      .reduce(
        (acc: React.ReactElement[], x) =>
          x ? [...acc, ...(Array.isArray(x) ? x : [x])] : acc,
        []
      );
  }

  if (schema['$ref']) {
    const { reference, propertyId } = getReferenceDataFromRef(schema['$ref']);

    return (
      <ReferenceField
        key={source}
        source={source}
        reference={reference}
        label={schema.title}
      >
        <TextField source={propertyId} />
      </ReferenceField>
    );
  } else if (schema.type === 'string') {
    if (schema.enum) {
      return (
        <SelectField
          source={source}
          choices={schema.enum.map((x, i) => ({
            id: x,
            name: (schema as any).enumNames
              ? (schema as any).enumNameschema[i] || JSON.stringify(x)
              : JSON.stringify(x),
          }))}
          label={schema.title}
        />
      );
    }
    if (schema.format) {
      const ViewComponent = ViewComponents[schema.format];
      if (ViewComponent) {
        return (
          <ViewComponent key={source} source={source} label={schema.title} />
        );
      }
    }

    return <TextField key={source} source={source} label={schema.title} />;
  } else if (schema.type === 'integer' || schema.type === 'number') {
    return <NumberField key={source} source={source} />;
  } else if (schema.type === 'boolean') {
    return <BooleanField key={source} source={source} />;
  } else {
    return null;
  }
};
