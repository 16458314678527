import { SortPayload } from 'react-admin';

import { sortByString } from '_helper/sortBy';

export const getPaginatedFromAll = <T>(
  list: T[],
  pagination: {
    perPage: number;
    page: number;
  },
  sort: SortPayload
) => {
  const per_page = pagination.perPage;
  const page = pagination.page;

  // const isNumber = list.length > 0 && typeof list[0][sort.field] === `number`

  return sortThem(list, sort).slice(per_page * (page - 1), per_page * page);
};

export const sortThem = <T>(list: T[], sort: SortPayload): T[] => {
  // const isNumber = list.length > 0 && typeof list[0][sort.field] === `number`

  return sortByString(
    list,
    (x) => String(x[sort.field]),
    sort.order === 'ASC' ? 'ASC' : 'DESC'
  );
};
