export const constructFromURL = <T extends { [key: string]: string }>(
  schema: string,
  allowEmpty: string[] = []
) => {
  const splitSchema = schema.split('/');

  return (pathname: string): T => {
    const returnValue = {};

    const splitPath = pathname.split('/');

    splitSchema.forEach((v, i) => {
      if (v.startsWith(':')) {
        const key = v.replace(':', '');
        if (splitPath[i]) {
          returnValue[key] = decodeURIComponent(splitPath[i]);
        } else if (!allowEmpty.includes(key)) {
          throw new Error(
            `E7584: url ${pathname} doesn't fit schema ${schema} for key ${key}`
          );
        }
      }
    });

    return returnValue as T;
  };
};

export const createFromTemplateString = <T extends { [key: string]: string }>(
  ts: string
) => {
  return (t: T) =>
    Object.entries(t).reduce(
      (acc, [key, value]) => acc.replace(`:${key}`, String(value)),
      ts
    );
};

export const constructReplaceResourceParamsInURL = <
  T extends { [key: string]: string }
>(
  ts: string
) => {
  return (pattern: string, params: Partial<T>): string => {
    const oldParams = constructFromURL<T>(ts)(pattern);

    return createFromTemplateString<T>(ts)({
      ...oldParams,
      ...params,
    });
  };
};
