import { ApolloQueryResult, QueryOptions } from '@apollo/client';
import * as typed from 'generated/graphql';
import { createApolloClient } from 'github/createApolloClient';
import { gql } from '@apollo/client';

function load<Args, Result>(opts: QueryOptions<Args, Result>) {
  return ({
    token,
    ...args
  }: Args & { token: string }): Promise<ApolloQueryResult<Result>> => {
    const client = createApolloClient({ token });

    return client.query<Result, Args>({
      ...opts,
      variables: args as any,
    });
  };
}

const prefix = `object`;

export const getFileQueryKeyFromIndex = (index: string | number) =>
  `${prefix}__${index}`;

export const getFilesQuery = (args: {
  ids: Array<number | string>;
  getExpression: (id: string | number) => string;
}) => `
  query fileContent ($owner: String!, $repo: String!) {
    rateLimit {
      limit
      cost
      used
    }
    repository(name: $repo, owner: $owner) {
      url
      ${args.ids.map(
        (id: number | string) => `${getFileQueryKeyFromIndex(
          id
        )}: object(expression: "${args.getExpression(id)}") {
          ... on Blob {
            text
            commitResourcePath
          }
        }`
      )}
    }
  }
`;

// Copied from typed FileContentQuery
export type FileContentQuery = { __typename?: 'Query' } & {
  rateLimit?: typed.Maybe<
    { __typename?: 'RateLimit' } & Pick<
      typed.RateLimit,
      'limit' | 'cost' | 'used'
    >
  >;
  repository?: typed.Maybe<
    { __typename?: 'Repository' } & {
      [key: string]: typed.Maybe<
        | ({ __typename?: 'Blob' } & Pick<
            typed.Blob,
            'text' | 'commitResourcePath'
          >)
        | { __typename?: 'Commit' }
        | { __typename?: 'Tag' }
        | { __typename?: 'Tree' }
      >;
    }
  >;
};

type getJSONArgs = {
  owner: string;
  repo: string;
  ids: Array<number | string>;
  getExpression: (id: string | number) => string;
  token: string;
};

const loadFiles = (args: getJSONArgs) => {
  const query = getFilesQuery(args);

  return load<typed.FileContentQueryVariables, FileContentQuery>({
    query: gql(query),
  })({
    owner: args.owner,
    repo: args.repo,
    token: args.token,
  });
};

export const typedGraphQLAPI = {
  loadRepos: load<{}, typed.LoadReposQuery>({
    query: typed.LoadRepos,
    errorPolicy: 'ignore',
  }),
  loadSchema: load<typed.LoadSchemaQueryVariables, typed.LoadSchemaQuery>({
    query: typed.LoadSchema,
  }),
  loadFiles,
  loadDir: load<typed.LoadDirQueryVariables, typed.LoadDirQuery>({
    query: typed.LoadDir,
  }),
  getJSON: async (args: getJSONArgs): Promise<Array<any>> => {
    const result = await loadFiles(args);

    const repo = result.data.repository || {};

    return args.ids.map((id: number | string) => {
      const key = getFileQueryKeyFromIndex(id);
      const data = repo[key] as any;
      const text = data?.text;

      try {
        return {
          ...JSON.parse(text),
          id,
        };
      } catch (e) {
        console.warn(e.message + '\n' + text);
        return {};
      }
    });
  },
};
