import React from 'react';
import { createControlledContext } from './internals/createControlledContext';
import { DataProvider } from 'react-admin';
import { useAuth } from './AuthContextProvider';
import { constructDataprovider } from 'dataprovider';

const dataProviderContext = createControlledContext<{
  dataProvider: DataProvider;
}>('dataProvider');
export const DataProviderContextProvider: React.FC = ({ children }) => {
  const ContextProvider = dataProviderContext.ContextProvider;
  const [auth] = useAuth();

  return (
    <ContextProvider
      value={{ dataProvider: constructDataprovider({ githubAuth: auth }) }}
    >
      {children}
    </ContextProvider>
  );
};

export const useDataProvider = dataProviderContext.use;
