import React from 'react';
import { useAuth } from './AuthContextProvider';
import { Auth } from 'auth/index';
import { PickRepo } from 'dataprovider/provider/LoadGithubRepos';
import { LoadGithubSchema } from 'dataprovider/provider/LoadGithubSchema';
import { LoadGithubPRs } from 'dataprovider/provider/LoadGithubPRs';
import { getRepoDataFromResource } from 'dataprovider/provider/getRepoDataFromResource';
import { useSnackbar } from 'notistack';
import { CustomSchemaDefinition, CustomSchema } from './UI';

export type ValueProps =
  | {
      type: 'github';
      owner: undefined;
      repo: undefined;
      branch: undefined;
    }
  | {
      type: 'github';
      owner: string;
      repo: undefined;
      branch: undefined;
    }
  | {
      type: 'github';
      owner: string;
      repo: string;
      branch: undefined;
    }
  | {
      type: 'github';
      owner: string;
      repo: string;
      branch: string;
    }
  | {
      type: 'demo';
      version: string;
    };

export const validateURLSchema = (s: string): ValueProps | undefined => {
  if (s.startsWith('/github')) {
    const [owner, repo, ...branch] = s
      .replace('/github', '')
      .replace('/', '')
      .split('/');

    return {
      type: 'github',
      owner,
      repo,
      branch: branch
        .slice(
          0,
          branch.findIndex((x) => x === `.gitlify`)
        )
        .join('/'),
    };
  } else if (s.startsWith('/demo')) {
    return {
      type: 'demo',
      version: s.replace('/demo', '').replace('/', '').split('/')[0],
    };
  } else {
    console.warn(`E4675: resourceName ${s} can not be parsed.`);
    return undefined;
  }
};

export const computePrefix = (args: ValueProps) => {
  if (args.type === `demo`) {
    return `demo`;
  } else {
    const { type, repo, owner, branch } = args;

    return [type, owner, repo, branch].filter(Boolean).join('/');
  }
};

const getDemoSchema = (_: string) => ({});

const loadSchemaFromArgs = async (
  s: string,
  auth: Auth
): Promise<CustomSchemaDefinition> => {
  const args = validateURLSchema(s);
  if (!args) {
    throw new Error(`E5748: name ${s} not supported`);
  } else if (args.type === 'demo') {
    return getDemoSchema([args.type, args.version].join('/'));
  } else {
    const { owner, repo, branch } = getRepoDataFromResource(s) || {};

    const [result, error] = await LoadGithubSchema.dataProvider({
      githubAuth: auth,
    })
      ?.getList(
        {
          owner,
          repo,
          branch,
        },
        undefined
      )
      .then(
        (d) => [d],
        (e) => [null, e]
      );

    if (error || !result) {
      return Promise.reject(error || `E5784: No results for resource ${s}`);
    }

    const initialSchema =
      owner && repo && branch
        ? {
            [LoadGithubPRs.getBaseURL({
              owner,
              repo,
              branch,
            })]: LoadGithubPRs.schema,
          }
        : {};

    return result.data.reduce(
      (acc, x) => ({
        ...acc,
        [x.id]: x.schema,
      }),
      initialSchema as CustomSchemaDefinition
    );
  }
};

type CustomSchemaContext = {
  schema: CustomSchemaDefinition;
  loadSchema: (path: string) => Promise<void>;
};

const Ctx = React.createContext<CustomSchemaContext | null>(null);

const isAlreadyLoaded = (s: string, schema: CustomSchemaDefinition) => {
  const args = validateURLSchema(s);

  if (!args) {
    return true;
  } else if (args.type === `demo`) {
    return Boolean(schema[`demo`]);
  } else {
    const { type, repo, owner, branch } = args;

    const path = [type, owner, repo, branch].filter(Boolean).join('/');

    return (
      Object.keys(schema).findIndex((x) =>
        branch ? x.startsWith(path) : x === path
      ) > -1
    );
  }
};

const initialSchema: CustomSchemaDefinition = {
  [PickRepo.resourceName]: PickRepo.schema as CustomSchema<any>,
  _demo: PickRepo.schema as CustomSchema<any>,
};

export const SchemaContextProvider: React.FC = ({ children }) => {
  const [schema, setValue] = React.useState<CustomSchemaDefinition>(
    initialSchema
  );
  const [auth] = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const loadSchema = async (pathname: string) => {
    // console.log(`loading`, pathname);
    if (isAlreadyLoaded(pathname, schema)) {
      // console.log(`abort loading`);
      return;
    }

    const data = await loadSchemaFromArgs(pathname, auth).catch((e) => {
      console.error(`E57854: Error loading schema`, e);
      enqueueSnackbar(JSON.stringify(e));

      return {};
    });

    const newValue = {
      ...schema,
      ...data,
    };

    if (
      JSON.stringify(newValue, Object.keys(newValue)) !==
      JSON.stringify(schema, Object.keys(schema))
    ) {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    loadSchema(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Ctx.Provider value={{ schema, loadSchema }} children={children} />;
};

export const useSchemaContext = (): CustomSchemaContext => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ctx = React.useContext(Ctx);

  if (ctx === null) {
    throw new Error(
      `E4695: Implementation error - Provider for schema context was forgotten`
    );
  }

  return ctx;
};

export const useSpecificSchema = (
  name: keyof CustomSchemaDefinition
): CustomSchema => {
  const schemas = useSchemaContext();
  const schema = schemas[name];

  if (schema) {
    return schema;
  } else {
    throw new Error(`E4698: Schema for ${name} not known.`);
  }
};
