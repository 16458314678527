import React from 'react';
import { Auth } from 'auth';
import { LoginScreen } from 'components/LoginScreen';
import { createStrictContext } from './internals/createStrictContext';

const authContext = createStrictContext<Auth>(
  'auth',
  ({ getValue, setValue, value }) => (
    <LoginScreen
      getAuth={getValue}
      setAuth={(auth) => {
        setValue(auth);
      }}
      auth={value}
    />
  )
);
export const AuthContextProvider = authContext.ContextProvider;

export const useAuth = authContext.use;
