import React from 'react';

export const createControlledContext = function <T>(ns: string) {
  const Ctx = React.createContext<T | null>(null);

  const ContextProvider: React.FC<{
    value: T;
  }> = ({ children, value }) => {
    return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
  };

  return {
    ContextProvider,
    use: (): T => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ctx = React.useContext(Ctx);

      if (ctx === null) {
        throw new Error(
          `E4675: Implementation error - Provider for ${ns} was forgotten`
        );
      }

      return ctx;
    },
  };
};
