import {
  constructFromURL,
  createFromTemplateString,
  constructReplaceResourceParamsInURL,
} from './constructFromURL';

const templateString = `github/:owner/:repo/:branch/.gitlify/:name`;

type RepoURLParamaters = {
  repo: string;
  owner: string;
  branch: string;
  name: string;
};

export const getResourceName = createFromTemplateString<RepoURLParamaters>(
  templateString
);
export const getResourceParamsFromURL = constructFromURL<RepoURLParamaters>(
  templateString
);
export const replaceResourceParamsInURL = constructReplaceResourceParamsInURL<RepoURLParamaters>(
  templateString
);
