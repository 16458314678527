import React from 'react';
import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from 'components/logo/logo';

const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
});

export const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar {...props} elevation={1} color="primary">
        <span className={classes.spacer} />
        <Logo />
        <span className={classes.spacer} />
      </AppBar>
    </React.Fragment>
  );
};
