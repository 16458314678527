import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GitHub } from '@material-ui/icons';
import { Loading } from './Loading';
import { ErrorComponent } from './Error';
import { Auth } from 'auth';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ReactComponent as Logo } from 'logo.svg';
import { FirebaseAuthInstance } from 'auth/auth';

export const LoginScreen: React.FC<{
  getAuth: () => Promise<Auth>;
  setAuth: (auth?: Auth) => void;
  auth?: Auth;
}> = ({ getAuth, setAuth, auth, children }) => {
  const [errors, setErrors] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const auth = await getAuth();
      setAuth(auth);
    } catch (e) {
      setErrors(e);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    const unsubscribe = FirebaseAuthInstance.auth().onAuthStateChanged(
      async (state) => {
        if (!state) {
          setAuth(undefined);
        } else {
          if (!auth && !loading) {
            handleClick();
          }
        }
      }
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !auth ? (
    <Dialog open={true}>
      <DialogTitle style={{ textAlign: 'center', paddingTop: 50 }}>
        <Logo height={40} />
        <Typography>GITLIFY CMS</Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: 'center', padding: '10px 60px 50px' }}>
          <Loading loading={loading}>
            Waiting for the response of Github.
          </Loading>
          <Button
            fullWidth
            startIcon={<GitHub />}
            onClick={handleClick}
            variant="contained"
            color="primary"
          >
            Connect
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="caption" component="p">
            By continuing, you are indicating that you accept our{' '}
            <a
              href="https://www.gitlify.com/legal/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.gitlify.com/legal/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </Typography>
          <br />
          <ErrorComponent errors={errors} tryAgain={handleClick} />
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};
