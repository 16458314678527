import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  EditButton,
  ListProps,
  TopToolbar,
} from 'react-admin';
import { JSONSchema7 } from 'json-schema';
import { BreadCrumb } from './BreadCrumb';
import { mapToRead } from './ViewComponents';
import { useLocation } from 'react-router-dom';
import { getRepoDataFromResource } from 'dataprovider/provider/getRepoDataFromResource';
import Button from '@material-ui/core/Button';
import { SwitchBranchModal } from './SwitchBranchModal';

const ReadOnlySwitch = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <SwitchBranchModal open={open} setOpen={setOpen} />
      <TopToolbar>
        <Button onClick={() => setOpen(true)}>Switch to edit mode</Button>
      </TopToolbar>
    </React.Fragment>
  );
};

const SwitchBranchOnEdit = () => {
  const location = useLocation();

  const readOnly = getRepoDataFromResource(location.pathname)?.readonly;

  console.log({ readOnly });

  if (readOnly) {
    return <ReadOnlySwitch />;
  }

  return (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  );
};

export const CustomShowGuesser: React.FC<
  {
    uiSchema?: object;
    schema: JSONSchema7;
    schemaName: string;
  } & ListProps
> = ({ schemaName, uiSchema, schema, ...props }) => {
  return (
    <React.Fragment>
      <BreadCrumb />
      <Show actions={<SwitchBranchOnEdit />} title={schema.title} {...props}>
        <SimpleShowLayout>
          {mapToRead({
            resource: schemaName,
            source: '',
            schema,
            shallow: true,
          })}
        </SimpleShowLayout>
      </Show>
    </React.Fragment>
  );
};
