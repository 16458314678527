import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.text.primary,
    },
  })
);

export const Loading: React.FC<{ loading: boolean }> = ({
  loading,
  children,
}) => {
  const classes = useStyles();

  return (
    <Backdrop open={loading} className={classes.backdrop}>
      <CircularProgress color="inherit" /> {children || 'Loading'}
    </Backdrop>
  );
};
