import { getGithubApi } from 'github/getGithubApi';

export type GetArgs = {
  owner: string;
  repo: string;
  branch: string;
  path: string;
  token: string;
  sortByKey?: boolean;
};

const stringifyJSON = (json: string, sortByKey = false) =>
  btoa(JSON.stringify(json, sortByKey ? Object.keys(json).sort() : null, 2));

const parseJSON = (content: string) => JSON.parse(atob(content));

export const createOrUpdateFile = async ({
  owner,
  repo,
  branch,
  path,
  sha,
  content,
  token,
  sortByKey,
}: GetArgs & { sha?: string; content: any }) => {
  const gh = getGithubApi({ token });
  await gh.repos.createOrUpdateFileContents({
    owner,
    repo,
    path,
    branch,
    message: `feat(gitlify.com): ${sha ? `update` : `create`} ${path}`,
    content: stringifyJSON(content, sortByKey),
    sha,
  });

  return content;
};

export const updateFile = async ({
  owner,
  repo,
  branch,
  path,
  updateContent,
  sortByKey,
  token,
}: GetArgs & { updateContent: (prevJSON: any) => any }) => {
  const gh = getGithubApi({ token });

  const resp = await gh.repos.getContent({
    owner,
    repo,
    ref: branch,
    path,
  });

  const data = resp.data;

  if (!('sha' in data)) {
    throw new Error(`E4567: No sha available for ${path} in ${owner}/${repo}`);
  }

  if (!('content' in data)) {
    throw new Error(
      `E4568: No content available for ${path} in ${owner}/${repo}`
    );
  }

  const { sha, content } = data;

  const json = [parseJSON, updateContent].reduce((acc, fn, index) => {
    try {
      return fn(acc);
    } catch (e) {
      throw new Error(`E4782: Error parsing string with ${index}-th function`);
    }
  }, content);

  return await createOrUpdateFile({
    owner,
    repo,
    branch,
    path,
    content: json,
    sha,
    token,
    sortByKey,
  });
};
