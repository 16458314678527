import React from 'react';

import { Layout, LayoutProps } from 'react-admin';
import { CustomAppBar } from './CustomAppbar';
import { CustomMenu } from './CustomMenu';

export const CustomLayout: React.FC<LayoutProps> = ({ ...props }) => {
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={(innerProps) => <CustomMenu {...innerProps} />}
    />
  );
};
