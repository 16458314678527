import { Octokit } from '@octokit/rest';
import { retry } from '@octokit/plugin-retry';
import { throttling } from '@octokit/plugin-throttling';

const isLocalhost = process.env.NODE_ENV === `development`;

const MyOctokit = Octokit.plugin(retry).plugin(throttling);

export const getGithubApi = ({
  token,
  parallelId,
}: { token?: string; parallelId?: string } = {}) => {
  const octokit = new MyOctokit({
    auth: token,
    // log: console,
    throttle: {
      id: parallelId,
      onRateLimit: (
        retryAfter: any,
        options: { [key: string]: any },
        octokit: Octokit
      ) => {
        octokit.log.warn(
          `Request quota exhausted for request ${options.method} ${options.url}`
        );

        if (options.request.retryCount === 0) {
          // only retries once
          octokit.log.info(`Retrying after ${retryAfter} seconds!`);
          return true;
        }
      },
      onAbuseLimit: (options: any, octokit: Octokit) => {
        // does not retry, only logs a warning
        octokit.log.warn(
          `Abuse detected for request ${options.method} ${options.url}`
        );
      },
    },
  });

  if (isLocalhost) {
    // eslint-disable-next-line
    (<any>window)['octokit'] = octokit;
  }

  return octokit;
};
