import { getGithubApi } from 'github/getGithubApi';
import { getDefaultBranch } from '../github/getDefaultBranch';

export const getBranchWithDefaultFallback = async ({
  repo,
  owner,
  branch,
  token,
}: {
  repo: string;
  owner: string;
  branch?: string;
  token;
}) => {
  const ghApi = getGithubApi({ token });

  return branch
    ? await ghApi.repos
        .getBranch({
          owner,
          repo,
          branch: branch,
        })
        .then(
          (resp) => resp.data.name,
          () =>
            getDefaultBranch({
              repo,
              owner,
              token,
            })
        )
    : await getDefaultBranch({
        repo,
        owner,
        token,
      });
};
