import {
  CustomProxyArgs,
  CustomResourceConfiguration,
} from 'dataprovider/types';
import { typedGraphQLAPI } from 'github/query';
import { sortByString } from '_helper/sortBy';
import { v4 as uuid } from 'uuid';
import { deleteFile } from 'dataprovider/github/deleteFile';
import { createFile } from 'dataprovider/github/createFile';
import { getOrCreateNewBranch } from 'dataprovider/github/getOrCreateNewBranch';
import { updateFileContent } from 'dataprovider/github/updateFileContent';
import { LoadGithubSchema } from './LoadGithubSchema';
import { getBranchWithDefaultFallback } from 'dataprovider/github/getBranchWithDefaultFallback';
import { getOrCreatePRAgainstDefaultBranch } from 'dataprovider/github/getOrCreatePR';
import { getPaginatedFromAll, sortThem } from './getPaginatedFromAll';

export type ResourceRouteProps = {
  type: string;
  owner: string;
  branch: string;
  repo: string;
  filePath: string;
};

const getDataPath = (resource: ResourceRouteProps, id?: string) =>
  `${resource.filePath}/data/${id ? `${id}.json` : ``}`;

const getDataPathExpression = (resource: ResourceRouteProps, id?: string) =>
  `${resource.branch}:${resource.filePath}/data/${id ? `${id}.json` : ``}`;

const addFallbackBranchToResourceProps = async (
  props: ResourceRouteProps,
  customProxyArgs: CustomProxyArgs
) => {
  const branch = await getBranchWithDefaultFallback({
    owner: props.owner,
    repo: props.repo,
    branch: props.branch,
    token: customProxyArgs.githubAuth.token,
  });

  return {
    ...props,
    branch,
  };
};

type WrappedPromise<S, W> = (_: ResourceRouteProps, __: S) => Promise<W>;

const withDefaultFallbackBranch = <S, W>(
  customProxyArgs: CustomProxyArgs,
  p: WrappedPromise<S, W>
): WrappedPromise<S, W> => {
  return (r: ResourceRouteProps, arg: S) =>
    addFallbackBranchToResourceProps(r, customProxyArgs).then((newR) =>
      p(newR, arg)
    );
};

const withCreateBranchIfNotExists = <S, W>(
  customProxyArgs: CustomProxyArgs,
  p: WrappedPromise<S, W>
): WrappedPromise<S, W> => {
  return (r: ResourceRouteProps, arg: S) =>
    getOrCreateNewBranch({
      owner: r.owner,
      repo: r.repo,
      branch: r.branch,
      token: customProxyArgs.githubAuth.token,
    })
      .then(() => p(r, arg))
      .then(async (data) => {
        const a = await getOrCreatePRAgainstDefaultBranch({
          owner: r.owner,
          repo: r.repo,
          branch: r.branch,
          token: customProxyArgs.githubAuth.token,
        });

        console.log('Creating PR', a);

        return data;
      });
};

const prefix = 'github';

export const LoadGithubFiles: CustomResourceConfiguration<
  ResourceRouteProps,
  any
> = {
  prefix,
  getBaseURL: (props: ResourceRouteProps) =>
    [prefix, props.owner, props.repo, props.branch, props.filePath].join('/'),
  getPropsFromResource: (resource: string) => {
    const [split, ...filePaths] = resource.split('/.gitlify/');

    const args = LoadGithubSchema.getPropsFromResource(split);

    return args && filePaths.length > 0
      ? {
          ...args,
          filePath: ['.gitlify', ...filePaths].join('/'),
        }
      : undefined;
  },
  dataProvider: (customProxyArgs: CustomProxyArgs) => ({
    getList: withDefaultFallbackBranch(
      customProxyArgs,
      async (resource, getListArgs) => {
        // TODO: Fix pagination
        // TODO: Fix filter

        // const ghApi = getGithubApi({ token });

        // const query = Object.entries({
        //   repo: `${org}/${repo}`,
        //   path: `/` + getDataPath(resource),
        // })
        //   .map(([k, v]) => [k, v].join(':'))
        //   .join('+');

        // const { data } = await ghApi.search.code({
        //   q: query,
        //   page: pagination.page,
        //   per_page: pagination.perPage,
        // });

        const args = {
          owner: resource.owner,
          repo: resource.repo,
          query: getDataPathExpression(resource),
          token: customProxyArgs.githubAuth.token,
        };

        const dir = await typedGraphQLAPI.loadDir(args);

        const folder = dir.data.repository?.folder;

        const files =
          folder?.__typename === 'Tree' && Array.isArray(folder.entries)
            ? sortByString(folder.entries || [], (x) => x.name)
            : [];

        const totalCount = files.length;

        const relevantFiles = getPaginatedFromAll(
          files,
          getListArgs.pagination,
          getListArgs.sort
        );

        const results = await typedGraphQLAPI.getJSON({
          owner: args.owner,
          repo: args.repo,
          ids: relevantFiles.map((x) => String(x.name).replace(/\.json$/, '')),
          getExpression: (name: string | number) =>
            getDataPathExpression(resource, String(name)),
          token: args.token,
        });

        const parsedResults = relevantFiles.map((x, index) => {
          const json = results[index];

          return {
            id: String(x.name).replace(/\.json$/, ''),
            ...json,
          };
        });

        return {
          total: totalCount,
          data: sortThem(parsedResults, getListArgs.sort),
        };
      }
    ),
    getOne: withDefaultFallbackBranch(
      customProxyArgs,
      async (resource, { id }) => {
        const args = {
          owner: resource.owner,
          repo: resource.repo,
          ids: [id],
          getExpression: (id: string | number) =>
            getDataPathExpression(resource, String(id)),
          token: customProxyArgs.githubAuth.token,
        };
        const results = await typedGraphQLAPI.getJSON(args);

        return {
          data: results[0],
        };
      }
    ),
    getMany: withDefaultFallbackBranch(
      customProxyArgs,
      async (resource, { ids }) => {
        const args = {
          owner: resource.owner,
          repo: resource.repo,
          branch: resource.branch,
          ids: ids,
          getExpression: (id: string | number) =>
            getDataPathExpression(resource, String(id)),
          token: customProxyArgs.githubAuth.token,
        };
        const results = await typedGraphQLAPI.getJSON(args);

        return {
          data: results,
        };
      }
    ),
    getManyReference: withDefaultFallbackBranch(
      customProxyArgs,
      async (resource, args) => {
        return Promise.reject(
          `E6378: getManyReferences not yet implemented for ${resource} and ${JSON.stringify(
            args
          )}`
        );
      }
    ),
    // With Write Hooks
    delete: withCreateBranchIfNotExists(customProxyArgs, (resource, { id }) =>
      deleteFile({
        owner: resource.owner,
        repo: resource.repo,
        branch: resource.branch,
        token: customProxyArgs.githubAuth.token,
        filePath: getDataPath(resource, String(id)),
      }).then(() => ({
        data: { id },
      }))
    ),
    deleteMany: withCreateBranchIfNotExists(
      customProxyArgs,
      (resource, { ids }) =>
        Promise.all(
          ids.map((id) =>
            deleteFile({
              owner: resource.owner,
              repo: resource.repo,
              branch: resource.branch,
              token: customProxyArgs.githubAuth.token,
              filePath: getDataPath(resource, String(id)),
            })
          )
        ).then(() => ({
          data: ids,
        }))
    ),
    create: withCreateBranchIfNotExists(
      customProxyArgs,
      async (resource, record) => {
        const id = uuid();

        const content = await createFile({
          owner: resource.owner,
          repo: resource.repo,
          branch: resource.branch,
          token: customProxyArgs.githubAuth.token,
          path: getDataPath(resource, id),
          content: record.data,
          sortByKey: true,
        });

        return {
          data: {
            ...content,
            id,
          },
        };
      }
    ),
    update: withCreateBranchIfNotExists(
      customProxyArgs,
      async (
        resource,
        {
          id,
          data,
          // previousData,
        }
      ) => ({
        data: {
          ...(await updateFileContent({
            owner: resource.owner,
            repo: resource.repo,
            branch: resource.branch,
            filePath: getDataPath(resource, String(id)),
            data,
            token: customProxyArgs.githubAuth.token,
          })),
          id: String(id),
        },
      })
    ),
    updateMany: withCreateBranchIfNotExists(
      customProxyArgs,
      async (resource, { ids, data }) => {
        if (Array.isArray(data) && data.length === ids.length) {
          await Promise.all(
            ids.map(async (id, index) => ({
              ...(await updateFileContent({
                owner: resource.owner,
                repo: resource.repo,
                branch: resource.branch,
                filePath: getDataPath(resource, String(id)),
                data: data[index],
                token: customProxyArgs.githubAuth.token,
              })),
              id: String(id),
            }))
          );

          return {
            data: ids as any[],
          };
        } else {
          return Promise.reject(
            `E4675: updateMany requires data to be an array: ${JSON.stringify(
              data
            )}`
          );
        }
      }
    ),
  }),
};
