import { CustomProxyArgs } from './types';
import { providerMethods } from './provider/';

import { wrapMethod } from './wrapMethod';
import {
  Record,
  UseDataProviderOptions,
  UpdateManyParams,
  GetManyReferenceResult,
  GetManyReferenceParams,
  UpdateManyResult,
  GetManyParams,
  GetManyResult,
  CreateParams,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  GetOneParams,
  DeleteManyParams,
  DeleteManyResult,
  DeleteResult,
  GetOneResult,
  DataProvider,
  DataProviderProxy,
  cacheDataProviderProxy,
} from 'react-admin';

export const emptyDataProvider = {
  getList: () =>
    Promise.resolve({
      data: [],
      total: 0,
    }),
  getOne: <RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> =>
    Promise.resolve({
      data: params as any,
    }),
  delete: <RecordType extends Record = Record>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> =>
    Promise.resolve({
      data: params as any,
    }),
  deleteMany: (
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> =>
    Promise.resolve({
      data: params.ids.map((id) => ({ id })) as any,
    }),
  update: <RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> =>
    Promise.resolve({
      data: params.previousData as any,
    }),
  create: (resource: string, params: CreateParams) =>
    Promise.resolve({
      data: { id: '1234' } as any,
    }),
  getMany: <RecordType extends Record = Record>(
    resource: string,
    params: GetManyParams,
    options?: UseDataProviderOptions
  ): Promise<GetManyResult<RecordType>> => Promise.resolve({ data: [] }),
  getManyReference: <RecordType extends Record = Record>(
    resource: string,
    params: GetManyReferenceParams,
    options?: UseDataProviderOptions
  ): Promise<GetManyReferenceResult<RecordType>> =>
    Promise.resolve({
      data: [],
      total: 10,
    }),
  updateMany: (
    resource: string,
    params: UpdateManyParams,
    options?: UseDataProviderOptions
  ): Promise<UpdateManyResult> => Promise.resolve({ data: params.ids }),
};

export const constructDataprovider = (
  customProxyArgs: CustomProxyArgs
): DataProvider | DataProviderProxy => {
  const wrapInner = (x: keyof typeof emptyDataProvider) =>
    wrapMethod<typeof x>(
      customProxyArgs,
      providerMethods,
      x,
      emptyDataProvider[x]
    );

  return cacheDataProviderProxy(
    {
      getList: wrapInner('getList'),
      getOne: wrapInner('getOne'),
      delete: wrapInner('delete'),
      deleteMany: wrapInner('deleteMany'),
      update: wrapInner('update'),
      create: wrapInner('create'),
      getMany: wrapInner('getMany'),
      getManyReference: wrapInner('getManyReference'),
      updateMany: wrapInner('updateMany'),
    } as DataProvider,
    30 * 1000
  );
};
