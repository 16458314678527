import firebase from '@firebase/app';
import { FirebaseAuth, GithubAuthProvider } from '@firebase/auth-types';

// These imports load individual services into the firebase namespace.
import '@firebase/auth';
import { firebaseConfig } from './firebase-config';
import { Auth as AuthType } from './';

export const FirebaseAuthInstance = firebase.initializeApp(
  firebaseConfig,
  'auth'
) as {
  auth: () => FirebaseAuth;
};

const getGithubProvider = (scopes: string[]): GithubAuthProvider => {
  const provider = new (firebase.auth as any).GithubAuthProvider();

  provider.setCustomParameters({
    allow_signup: 'false',
  });

  provider.addScope('repo');

  scopes.forEach((s) => {
    provider.addScope(s);
  });

  return provider;
};

export const loginWithGithub = (
  scopes: string[] = ['read:org']
): Promise<AuthType> => {
  const provider = getGithubProvider(scopes);

  return FirebaseAuthInstance.auth()
    .signInWithPopup(provider)
    .then((data: any) => {
      return {
        token: data.credential.accessToken,
      };
    });
};

export const logout = () => FirebaseAuthInstance.auth().signOut();
