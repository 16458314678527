import { CustomIcon } from 'components/CustomIcon';
import { CustomLayout } from 'components/CustomLayout';
import { CustomListGuesser } from 'components/CustomListGuesser';
import { CustomEditGuesser } from 'components/CustomEditGuesser';
import { AppWrapper } from 'context';
import { useSchemaContext } from 'context/SchemaContextProvider';
import React from 'react';
import { Resource, Create, AdminUI } from 'react-admin';

import { CustomLoginPage } from 'components/CustomLoginPage';
import { useTheme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const UpdateSchemas = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { loadSchema } = useSchemaContext();

  React.useEffect(() => {
    loadSchema(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <div data-goal="update-schema" />;
};

const Resources = () => {
  const { schema: schemaDefn } = useSchemaContext();

  const theme = useTheme();

  return (
    <AdminUI
      layout={CustomLayout}
      disableTelemetry
      loginPage={CustomLoginPage}
      theme={theme}
    >
      {Object.keys(schemaDefn).map((name) => {
        const { schema, ui, config } = schemaDefn[name] || {};

        const { noEdit, noCreate, noList, noShow } = config || {};

        return (
          <Resource
            name={name}
            key={name}
            options={{
              label: schema.title || name,
            }}
            icon={() => <CustomIcon config={config} alt={name} />}
            list={(props) =>
              noList ? null : (
                <CustomListGuesser
                  schemaName={name}
                  {...props}
                  schema={schema}
                  uiSchema={ui}
                  hasCreate={!noCreate}
                  hasEdit={!noEdit}
                  hasShow={!noShow}
                />
              )
            }
            edit={(props) =>
              noEdit ? null : (
                <CustomEditGuesser
                  {...props}
                  schema={schema}
                  schemaName={name}
                  allSchemas={schemaDefn}
                />
              )
            }
            create={(props) =>
              noCreate ? null : (
                <CustomEditGuesser
                  {...props}
                  WrapperComp={Create}
                  schema={schema}
                  schemaName={name}
                  allSchemas={schemaDefn || {}}
                />
              )
            }
          />
        );
      })}
      <UpdateSchemas />
    </AdminUI>
  );
};

const App = () => (
  <AppWrapper>
    <Resources />
  </AppWrapper>
);

export default App;
