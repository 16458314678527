import * as React from 'react';
import { List, EditButton, ListProps, Datagrid } from 'react-admin';
import { JSONSchema7 } from 'json-schema';
import CustomizableDatagrid from './ra-customizable-datagrid';
import { BreadCrumb } from './BreadCrumb';
import { PostFilter, mapToRead } from './ViewComponents';
import { PickRepo } from 'dataprovider/provider/LoadGithubRepos';
import { LoadGithubFiles } from 'dataprovider/provider/LoadGithubFiles';

export const RepoList: React.FC<
  {
    uiSchema?: object;
    schema: JSONSchema7;
    resource: string;
  } & ListProps
> = ({ uiSchema, schema, resource, ...listProps }) => {
  return (
    <React.Fragment>
      <BreadCrumb />
      <List
        title="Switch to other project"
        {...listProps}
        empty={<div>Empty</div>}
        hasCreate
        exporter={false}
        resource={resource}
      >
        <Datagrid
          rowClick={(_id, _basePath, record) => {
            return LoadGithubFiles.getBaseURL({
              owner: record.owner,
              repo: record.name,
              filePath: '',
              branch: record.default_branch,
              type: LoadGithubFiles.prefix,
            });
          }}
        >
          {mapToRead({ resource, source: '', schema, shallow: true })}
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export const CustomListGuesser: React.FC<
  {
    uiSchema?: object;
    schema: JSONSchema7;
    schemaName: string;
  } & ListProps
> = ({ schemaName, uiSchema, schema, ...props }) => {
  if (schemaName === PickRepo.resourceName) {
    return (
      <RepoList
        uiSchema={uiSchema}
        schema={schema}
        resource={schemaName}
        {...props}
      />
    );
  }

  return (
    <React.Fragment>
      <BreadCrumb />
      <List title={schema.title} filters={<PostFilter />} {...props}>
        <CustomizableDatagrid>
          {mapToRead({
            resource: schemaName,
            source: '',
            schema,
            shallow: true,
          })}
          <EditButton />
        </CustomizableDatagrid>
      </List>
    </React.Fragment>
  );
};
