import { CustomResourceConfiguration } from '../types';

import { LoadGithubFiles } from './LoadGithubFiles';
import { PickRepo } from './LoadGithubRepos';
import { LoadGithubSchema } from './LoadGithubSchema';
import { LoadGithubPRs } from './LoadGithubPRs';

export const providerMethods = {
  PickRepo,
  LoadGithubSchema,
  LoadGithubFiles,
  LoadGithubPRs,
} as { [key: string]: CustomResourceConfiguration<any, any> };
