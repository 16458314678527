import {
  CustomProxyArgs,
  CustomResourceConfiguration,
} from 'dataprovider/types';

import { LoadGithubSchema } from './LoadGithubSchema';
import { getPRs } from 'dataprovider/github/getOrCreatePR';
import { JSONSchemaType } from 'ajv';
import { JSONSchema7 } from 'json-schema';
import { getPaginatedFromAll } from './getPaginatedFromAll';

export type ResourceRouteProps = {
  owner: string;
  branch: string;
  repo: string;
};

const prefix = 'github_prs';

export type RepoData = {
  id: string;
  name: string;
};

export const repoDataSchema: JSONSchemaType<RepoData> & JSONSchema7 = {
  type: `object`,
  required: ['id', 'name'],
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: `string`,
    },
  },
};

export const LoadGithubPRs: CustomResourceConfiguration<
  ResourceRouteProps,
  any
> = {
  prefix,
  getBaseURL: (props: ResourceRouteProps) =>
    [prefix, props.owner, props.repo, props.branch].join('/'),
  schema: {
    schema: repoDataSchema as any,
    config: {
      iconClass: `fab fa-github`,
      noEdit: true,
      noCreate: true,
      noShow: true,
    },
    ui: {},
  },
  getPropsFromResource: (resource: string) => {
    const [split, ...filePaths] = resource.split('/.gitlify/');
    const args = LoadGithubSchema.getPropsFromResource(split);
    return args && filePaths.length > 0
      ? {
          ...args,
          filePath: ['.gitlify', ...filePaths].join('/'),
        }
      : undefined;
  },
  dataProvider: (customProxyArgs: CustomProxyArgs) => ({
    getList: async (resource, getListArgs) => {
      const data = await getPRs({
        ...resource,
        token: customProxyArgs.githubAuth.token,
      });

      return {
        data: getPaginatedFromAll(
          data,
          getListArgs.pagination,
          getListArgs.sort
        ),
        total: data.length,
      };
    },
  }),
};
