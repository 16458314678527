import { typedGraphQLAPI } from 'github/query';
import { Auth } from 'auth';

export type Org = {
  login: string;
  name: string;
  isPersonal: boolean;
  avatar: string;
  repos: Array<{
    hasGitlifyFolder: boolean;
    name: string;
    url: string;
    defaultBranch?: string;
    gitlifyBranches: Array<{
      name: string;
    }>;
    isPublic: boolean;
  }>;
};

export const getOrgs = async ({ token }: Auth): Promise<Org[]> => {
  const res = await typedGraphQLAPI.loadRepos({ token });

  const { data, error } = res || {};

  const nodes = data.viewer.organizations.nodes?.filter(ExcludesFalse) || [];

  const orgs = [data.viewer, ...nodes].filter(ExcludesFalse).map(
    (org, index): Org => ({
      login: org.login,
      name: org.name || org.login,
      isPersonal: index === 0,
      avatar: org.avatarUrl,
      repos:
        org.repositories?.nodes?.filter(ExcludesFalse).map((x) => ({
          hasGitlifyFolder: Boolean(x.object),
          name: x.name,
          url: x.url,
          defaultBranch: x.defaultBranchRef
            ? x.defaultBranchRef.name
            : undefined,
          gitlifyBranches: [],
          isPublic: !x.isPrivate,
        })) || [],
    })
  );

  if (error) {
    console.log(error);
  }

  return orgs;
};
function ExcludesFalse<T>(
  x: T | false | undefined | null
): x is NonNullable<T> {
  return Boolean(x) && typeof x === `object`;
}
