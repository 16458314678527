export const tryParseJson = (x: string): object | string | undefined => {
  try {
    const data = JSON.parse(x);

    if (typeof data === `string`) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return undefined;
  }
};
