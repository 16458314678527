import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

export const createApolloClient = ({ token }: { token: string }) => {
  // console.log('apollo-client')
  const cache = new InMemoryCache();
  const link = createHttpLink({
    uri: `https://api.github.com/graphql`,
    headers: {
      Authorization: `bearer ${token}`,
    },
  });

  return new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    link: link,
    queryDeduplication: false,
  });
};
