import * as React from 'react';
import { CustomSchemaConfig } from 'context/CustomSchemaConfig';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';

export const CustomIcon: React.FC<CustomIconProps> = ({
  config,
  alt,
  style,
}) => {
  const avatarStyle = {
    width: 24,
    height: 24,
    fontSize: 14,
    lineHeight: 24,
    backgroundColor: stringToColor(alt),
    ...style,
  };
  if (config?.iconSrc) {
    return (
      <Avatar
        style={avatarStyle}
        className={config?.iconClass}
        alt={alt}
        src={config?.iconSrc}
      />
    );
  } else if (config?.iconClass) {
    return (
      <Icon
        style={{ display: `flex`, justifyContent: `center`, ...style }}
        className={config?.iconClass}
        aria-label={alt}
      />
    );
  } else {
    return (
      <Avatar style={avatarStyle} alt={alt}>
        {String(alt[0]).toUpperCase()}
      </Avatar>
    );
  }
};
type CustomIconProps = {
  config?: CustomSchemaConfig;
  alt: string;
  style?: any;
};
// https://github.com/mui-org/material-ui/issues/12700#issuecomment-416869593
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}
