import { JSONSchemaType } from 'ajv';
import {
  CustomProxyArgs,
  StaticCustomResourceConfiguration,
} from 'dataprovider/types';
import { getOrgs, Org } from 'github/getOrgs';
import { JSONSchema7 } from 'json-schema';
import { GetListParams, GetListResult } from 'react-admin';
import { getPaginatedFromAll } from './getPaginatedFromAll';

export type RepoData = {
  owner: string;
  name: string;
  id: string;
  default_branch: string;
};

export const repoDataSchema: JSONSchemaType<RepoData> & JSONSchema7 = {
  type: `object`,
  required: ['id', 'owner', 'name'],
  properties: {
    id: {
      type: 'string',
    },
    owner: {
      type: `string`,
    },
    name: {
      type: `string`,
    },
    default_branch: {
      type: `string`,
    },
  },
};

const github_repos = `github_repos`;

type ResourceProps = {
  type: typeof github_repos;
};

export const PickRepo: StaticCustomResourceConfiguration<
  ResourceProps,
  RepoData
> = {
  prefix: github_repos,
  resourceName: github_repos,
  getBaseURL: () => github_repos,
  getPropsFromResource: (resource: string) => {
    return resource === github_repos ? { type: github_repos } : undefined;
  },
  schema: {
    schema: repoDataSchema as any,
    config: {
      iconClass: `fab fa-github`,
      noEdit: true,
      noCreate: true,
      noShow: true,
    },
    ui: {},
  },
  dataProvider: (customProxyArgs: CustomProxyArgs) => ({
    getList: async (
      _: ResourceProps,
      args: GetListParams
    ): Promise<GetListResult<RepoData>> => {
      const orgs = await getOrgs(customProxyArgs.githubAuth);

      const list: RepoData[] = orgs.reduce(
        (acc: RepoData[], y: Org) =>
          [
            ...acc,
            ...y.repos
              .filter((_) => _.hasGitlifyFolder)
              .map((repo) => ({
                id: [y.login, repo.name].join('/'),
                owner: y.login,
                name: repo.name,
                default_branch: repo.defaultBranch,
              }))
              .filter((_) => Boolean(_.default_branch)),
          ] as RepoData[],
        [] as RepoData[]
      );

      return {
        total: list.length,
        data: getPaginatedFromAll(list, args.pagination, args.sort),
      };
    },
  }),
};
