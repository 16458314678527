import { GetArgs, createOrUpdateFile } from './createOrUpdateFile';

export const createFile = ({
  content,
  sortByKey,
  ...args
}: GetArgs & { content: any }) =>
  createOrUpdateFile({
    ...args,
    content,
  });
